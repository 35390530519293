module.exports = [{
      plugin: require('../../../node_modules/@wardpeet/gatsby-plugin-static-site/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Vistaprint Digital Products","short_name":"workspace","icon":"src/images/vistaprint.png","start_url":"/digital-marketing","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"5556c45ff7c7b9cebc3666824022f5e6"},
    },{
      plugin: require('../../gatsby-plugin-rollbar-provider/gatsby-browser.js'),
      options: {"plugins":[],"accessToken":"dcadba443d5745a9b2429f04d70fb1cf","captureUncaught":true,"captureUnhandledRejections":true,"payload":{"environment":"production","client":{"javascript":{"code_version":"5020b9f","source_map_enabled":true,"guess_uncaught_frames":true}}}},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
